import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import NProgress from 'nprogress'
import Loading from './components/loading/index.vue'
import 'nprogress/nprogress.css'
import { globalRegister } from './global'
import 'windi.css'
// 显示右上角螺旋加载提示
NProgress.configure({ showSpinner: false })

Vue.config.productionTip = false
Vue.use(globalRegister)
Vue.component('Loading', Loading)

router.beforeEach((to, from, next) => {
  if (to.matched.length == 0) {
    return next('/')
  }
  // 开启进度条
  NProgress.start()
  next()
})

router.afterEach(() => {
  // 关闭进度条
  NProgress.done()
})

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
