<template>
  <div class="navbar">
    <div
      v-if="$route.path != '/account-registers'"
      class="nav_one"
      ref="nav_one"
      :class="navBarFixed ? 'navbar_bottom ' : 'navbar_top'"
    >
      <div class="welcome" v-if="!navBarFixed">
        <div class="content">您好！感谢使用赛小狸赛事报名系统~</div>
      </div>
      <div class="list">
        <div class="content flex_space_between">
          <div class="list_left flex" @click="$router.push('/')">
            <img v-if="$store.state.config" :src="$store.state.config.logo" />
            <span>赛小狸赛事报名系统</span>
          </div>
          <div class="list_center flex">
            <div
              class="list_center_item flex-center"
              :class="{ active: navIndex == index }"
              v-for="(item, index) in list"
              :key="index"
              @click="itemClick(item, index)"
            >
              <p>{{ item.text }}</p>
            </div>
          </div>
          <div class="list_right flex">
            <div v-if="!$store.state.userInfo" class="list_right_item flex" @click="$router.push('/account-login')">
              <img src="@/assets/image/icon/01.png" />
              <span>登录/注册</span>
            </div>
            <!--            <div v-if="!$store.state.userInfo" class="list_right_item flex" @click="open">-->
            <!--              <img src="@/assets/image/icon/02.png" />-->
            <!--              <span>我是组织方</span>-->
            <!--            </div>-->
            <div class="list_right_item flex" v-if="$store.state.userInfo" @click="$router.push('/mine')">
              <!-- <span class="one_line" style="max-width: 70px">{{ $store.state.userInfo.nick_name }}</span>
              <p class="flex">进入</p>
              <img @click.stop="outClick" src="../../assets/image/icon/29.png" /> -->
              <img class="avatar" :src="$store.state.userInfo.avatar" alt="" />
              <span class="name one_line">{{ $store.state.userInfo.nick_name }}</span>
              <p @click.stop="outClick">[退出]</p>
            </div>
          </div>
        </div>
      </div>
      <div class="seat" v-if="$route.meta.iseat">
        <div class="content flex_space_between">
          <div class="seat_left flex">
            <img src="@/assets/image/icon/06.png" />
            <p>您现在的位置：</p>
            <div class="seat_item flex" v-for="(item, index) in seatList" :key="index">
              <span :style="index == seatList.length - 1 ? 'color:#C59053' : ''">
                {{ item.name }}
              </span>
              <span v-if="index != seatList.length - 1">></span>
            </div>
          </div>
          <div class="seat_right flex" @click="backClick">
            <span>返回</span>
            <img src="@/assets/image/icon/07.png" />
          </div>
        </div>
      </div>
    </div>
    <div class="nav_two" v-if="$route.path == '/account-registers'">
      <div class="content flex_space_between">
        <div class="nav_two_left flex" @click="$router.push('/')">
          <img src="@/assets/image/logo_three.png" />
          <span class="flex">赛小狸赛事报名系统</span>
          <span>欢迎注册</span>
        </div>
        <div class="nav_two_right flex">
          <span>已有账号</span>
          <span @click="open">请登录</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { outLogin } from '@/service/api/user'
export default {
  data() {
    return {
      navIndex: 0,
      navBarFixed: false,
      list: [
        {
          text: '首页',
          url: '/'
        },
        {
          text: '赛事',
          url: '/match'
        },
        // {
        //   text: '活动',
        //   url: '/activity'
        // },
        {
          text: '成绩',
          url: '/score'
        },
        {
          text: '资讯',
          url: '/news'
        },
        {
          text: '关于我们',
          url: '/lianxi'
        }
      ],
      seatList: []
    }
  },

  mounted() {},
  methods: {
    async outLogin() {
      const res = await outLogin()
      if (res.code == 1) {
        this.$message.success('退出成功')
        this.$store.state.userInfo = ''
        localStorage.removeItem('user-info')
        this.$router.push('/')
      }
    },
    itemClick(item, index) {
      this.navIndex = index
      this.$router.push(item.url)
    },
    backClick() {
      this.$router.go(-1)
    },
    outClick() {
      this.outLogin()
    },
    open() {
      window.open(process.env.VUE_APP_BASE_URL + '/seller', '_blank')
    }
  },
  watch: {
    $route(val) {
      this.navIndex = val.matched[0].meta.navBarIndex
      this.seatList = []
      val.matched.forEach((item) => {
        if (JSON.stringify(item.meta) != '{}') {
          item.meta.path = item.path
          this.seatList.push(item.meta)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.navbar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  background-color: #f4f6f9;
}
@keyframes topIndown {
  0% {
    -webkit-transform: translate3d(0, -20%, 0);
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}
.active {
  color: #fff;
  background-color: @color_one;
}
.navbar_bottom {
  top: 0;
  width: 100%;
  z-index: 99;
  position: fixed;
  animation: topIndown 0.6s normal;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
}
.welcome {
  height: 30px;
  line-height: 30px;
  color: @color_five;
  font-size: @font_size_12;
  .content {
    text-align: right;
  }
}
.list {
  width: 100%;
  height: 100px;
  background: #fff;
  line-height: 100px;
  .list_left {
    cursor: pointer;
    // width: 332px;
    margin-right: 18px;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    span {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .list_center {
    .list_center_item {
      width: 110px;
      height: 100px;
      cursor: pointer;
      text-align: center;
      p {
        height: 39px;
        // font-weight: bold;
        line-height: 39px;
        display: inline-block;
        font-size: @font_size_20;
        border-bottom: 1px solid #fff;
      }
      &:hover {
        color: #fff;
        background-color: @color_one;
      }
    }
  }
  .list_right {
    margin-left: 70px;
    .list_right_item {
      cursor: pointer;
      .avatar {
        width: 34px;
        height: 34px;
        object-fit: cover;
        border-radius: 50%;
      }
      .name {
        max-width: 69px;
        height: 22px;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        margin-left: 8px;
      }
      p {
        width: 51px;
        height: 22px;
        font-size: 16px;
        line-height: 22px;
        color: #0099ff;
        margin-left: 15px;
      }
      &:nth-child(1) {
        margin-right: 58px;
      }
      img {
        width: 24px;
        height: 24px;
      }
      span {
        margin-left: 5px;
        // color: #999;
        font-size: @font_size_16;
      }
      // p {
      //   color: #999;
      //   margin-left: 10px;
      //   margin-right: 25px;
      //   font-size: @font_size_16;
      //   &::after {
      //     color: #c60021;
      //     content: '\4e2a\4eba\4e2d\5fc3';
      //   }
      // }
    }
  }
}
.seat {
  width: 100%;
  background: @color_bg_one;
  font-size: @font_size_16;
  .seat_left {
    height: 60px;
    img {
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
    p {
      height: 22px;
      line-height: 22px;
      color: @color_seven;
    }
    .seat_item {
      cursor: pointer;
      span {
        height: 22px;
        line-height: 22px;
        margin-right: 10px;
        color: @color_seven;
      }
    }
  }
  .seat_right {
    cursor: pointer;
    span {
      height: 22px;
      line-height: 22px;
      color: @color_five;
      font-size: @font_size_14;
    }
    img {
      width: 13px;
      height: 10px;
      margin-left: 5px;
    }
  }
}
.nav_two {
  background-color: #fff;
  .content {
    padding: 22px 0;
    .nav_two_left {
      img {
        width: 56px;
        height: 56px;
      }
      span {
        &:nth-child(2) {
          height: 48px;
          margin-left: 10px;
          line-height: 48px;
          font-weight: bold;
          font-size: @font_size_34;
          &::after {
            width: 1px;
            content: '';
            height: 20px;
            margin: 0 39px;
            background-color: #ddd;
          }
        }
        &:nth-child(3) {
          height: 40px;
          line-height: 40px;
          font-size: @font_size_28;
        }
      }
    }
    .nav_two_right {
      span {
        &:nth-child(1) {
          height: 28px;
          margin-right: 15px;
          line-height: 28px;
          font-size: @font_size_20;
        }
        &:nth-child(2) {
          width: 95px;
          height: 44px;
          cursor: pointer;
          line-height: 44px;
          text-align: center;
          border-radius: 50px;
          color: @color_thirteen;
          border: 1px solid @color_thirteen;
        }
      }
    }
  }
}
</style>
